'use strict';

Gri.module({
  name: 'search-alpha',
  ieVersion: null,
  $el: $('.search-alpha'),
  container: '.search-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let $textContainer = $('.search-alpha-content');
    let $resultContainer = $('.results-alpha');
    let $searchContainer = $('.search-alpha-wrapper');
    let $inputWrapper = $this.find('.input-wrapper');
    // endregion

    // region run
    $this.find('input').focus(function () {
      $(this).parent().addClass('focused');
      $(this).attr('placeholder', '');
    });

    $this.find('input').blur(function () {
      $(this).parent().removeClass('focused');
      $(this).attr('placeholder', $(this).data('placeholder'));
    });

    $('.search-alpha-close').on('click', function () {
      $searchContainer.removeClass('open');
      $inputWrapper.find('input').val('');
      $resultContainer.find('li').remove();
    });

    function esc() {
      document.addEventListener("keydown", function (event) {
        if (event.keyCode === 27) {
          $searchContainer.removeClass('open');
          $inputWrapper.find('input').val('');
          $resultContainer.find('li').remove();
        }
      });
    }

    $(window).on('resize', searchHeightController);
    searchHeightController();
    // endregion

    // region events
    $inputWrapper.find('input').keyup(function () {
      let thisVal = $(this).val();
      keyController(thisVal);
    });
    // endregion

    // region events functions
    function keyController(key) {
      if(key.length > 2) {
        $resultContainer.find('li').remove();
        $.ajax(`/arama?search=${key}`).success(function (results) {
          console.log(results);
          $.each(results, function (index, item) {
            let listTmp = `
              <li>
                  <a href="${item.Url}">
                      <span>${item.Title}</span>
                      <i class="icon-arrow-right"></i>
                    </a>
              </li>
            `;
            $resultContainer.find('ul').append(listTmp);
          })
        })
      } else if(key.length === 0) {
        $resultContainer.find('li').remove();
      }
    }
    // endregion

    // region functions
    function searchHeightController() {
      let windowW = $(window).width();
      let windowH = $(window).height();
      let textContentH = $textContainer.outerHeight();
      if(windowW < 992) {
        if(windowH < $this.outerHeight()) {
          $resultContainer.height(windowH - textContentH - 117);
        }
      } else if(windowW < 1600) {
        if(windowH < $this.outerHeight()) {
          $resultContainer.height(windowH - textContentH - 200);
        }
      } else {
        if(windowH < $this.outerHeight()) {
          $resultContainer.height(windowH - textContentH - 250);
        }
      }
    }
    // endregion

  }
});
